var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "sm"
    }
  }, [_c('div', {
    staticClass: "row mt-2"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('h1', {
    staticClass: "h3 text-primary"
  }, [_vm._v("Detail Log OFS")])]), _c('h4', [_c('router-link', {
    attrs: {
      "to": {
        name: _vm.CatalogOfsPath.name
      }
    }
  }, [_c('span', {
    staticClass: "badge badge-primary"
  }, [_c('i', {
    staticClass: "fa fa-rotate-left mr-1"
  }), _vm._v("Back")])])], 1), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "card border-0"
  }, [_c('div', {
    staticClass: "card-header bg-primary"
  }, [_c('h5', [_vm._v(" History ")])]), _c('br'), _c('div', {
    staticClass: "row justify-content-end mx-3"
  }, [_c('button', {
    staticClass: "btn btn-sm btn-dark",
    on: {
      "click": _vm.getItemById
    }
  }, [_c('i', {
    staticClass: "fa fa-refresh"
  })]), _c('label', {
    staticClass: "col-sm-auto"
  }, [_vm._v("Total :")]), _c('strong', {
    staticClass: "col-sm-auto"
  }, [_vm._v(_vm._s(_vm.totalRows))])]), _c('div', {
    staticClass: "card-body"
  }, [_c('b-table', {
    attrs: {
      "sticky-header": "400px",
      "busy": _vm.isLoading,
      "show-empty": "",
      "fields": _vm.fields,
      "hover": "",
      "responsive": "",
      "items": _vm.items
    },
    scopedSlots: _vm._u([{
      key: "cell(No)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s((_vm.page - 1) * _vm.limit + (row.index + 1)) + ". ")];
      }
    }, {
      key: "cell(created)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(_vm.formatDate(row.item.created)) + " ")];
      }
    }, {
      key: "cell(modified)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(_vm.formatDate(row.item.modified)) + " ")];
      }
    }, {
      key: "cell(is_success)",
      fn: function (row) {
        return [row.item.is_success ? _c('div', [_c('span', {
          staticClass: "badge badge-success"
        }, [_c('i', {
          staticClass: "fa fa-check mr-1"
        }), _vm._v(" succeed ")])]) : _c('div', [_c('span', {
          staticClass: "badge badge-danger"
        }, [_c('i', {
          staticClass: "fa fa-close mr-1"
        }), _vm._v("failed")])])];
      }
    }])
  }), _c('b-row', {
    staticClass: "justify-content-start p-1"
  }, [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.limit,
      "aria-controls": "my-table"
    },
    model: {
      value: _vm.page,
      callback: function ($$v) {
        _vm.page = $$v;
      },
      expression: "page"
    }
  })], 1)], 1)], 1)])])])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }