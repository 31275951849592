<template>
  <b-overlay :show="isLoading" rounded="sm">
    <div class="row mt-2">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center">
              <h1 class="h3 text-primary">Detail Log OFS</h1>
            </div>
            <h4>
              <router-link
                :to="{
                  name: CatalogOfsPath.name,
                }"
              >
                <span class="badge badge-primary"
                  ><i class="fa fa-rotate-left mr-1"></i>Back</span
                >
              </router-link>
            </h4>
            <div class="row">
              <div class="col-md-12">
                <div class="card border-0">
                  <div class="card-header bg-primary">
                    <h5>
                      History
                    </h5>
                  </div>
                  <br />
                  <div class="row justify-content-end mx-3">
                    <button class="btn btn-sm btn-dark" @click="getItemById">
                      <i class="fa fa-refresh"></i>
                    </button>
                    <label class="col-sm-auto">Total :</label>
                    <strong class="col-sm-auto">{{ totalRows }}</strong>
                  </div>
                  <div class="card-body">
                    <b-table
                      sticky-header="400px"
                      :busy="isLoading"
                      show-empty
                      :fields="fields"
                      hover
                      responsive
                      :items="items"
                    >
                      <template #cell(No)="row">
                        {{ (page - 1) * limit + (row.index + 1) }}.
                      </template>

                      <template #cell(created)="row">
                        {{ formatDate(row.item.created) }}
                      </template>

                      <template #cell(modified)="row">
                        {{ formatDate(row.item.modified) }}
                      </template>

                      <template #cell(is_success)="row">
                        <div v-if="row.item.is_success">
                          <span class="badge badge-success">
                            <i class="fa fa-check mr-1"></i>
                            succeed
                          </span>
                        </div>
                        <div v-else>
                          <span class="badge badge-danger"
                            ><i class="fa fa-close mr-1"></i>failed</span
                          >
                        </div>
                      </template>
                    </b-table>
                    <b-row class="justify-content-start p-1">
                      <b-col lg="3">
                        <b-pagination
                          v-model="page"
                          :total-rows="totalRows"
                          :per-page="limit"
                          aria-controls="my-table"
                        />
                      </b-col>
                    </b-row>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { CatalogOfsPath } from '../../router/monitoring';
export default {
  data() {
    return {
      page: 1,
      limit: 50,
      fields: [
        { key: 'No', label: 'No' },
        { key: 'item_name', label: 'Name' },
        { key: 'created', label: 'Created' },
        { key: 'modified', label: 'Modified' },
        { key: 'is_success', label: 'Status' },
        { key: 'log_message', label: 'Message' },
      ],
      CatalogOfsPath,
    };
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Detail OFS | Content Management System Ebooks Gramedia.com';
      },
    },

    page: function() {
      this.getItemById();
    },

    isError: function() {
      if (!this.isError) return;
      this.alertMsg(this.ErrorMessage, 'error')
    },

    items: function() {
      if (!this.items) return;
    },
  },

  computed: {
    ...mapState({
      isLoading: (state) => state.logOfs.isLoading,
      items: (state) => state.logOfs.items,
      totalRows: (state) => state.logOfs.totalRows,
      isError: (state) => state.logOfs.isError,
      ErrorMessage: (state) => state.logOfs.ErrorMessage,
    }),
  },

  created: function() {
    this.getItemById();
  },

  methods: {
    ...mapActions('logOfs', ['fetchOfsByItemID']),

    getItemById() {
      const itemID = this.$route.params.id;
      const payload = {
        itemId: itemID,
        page: this.page,
        limit: this.limit,
      };
      this.fetchOfsByItemID(payload);
    },

    formatDate(tgl) {
      return new Date(tgl).toString();
    },

    alertMsg(msg, type) {
      this.$swal({
        icon: type,
        toast: true,
        position: 'top-end',
        title: msg,
        timer: 10000,
        text: this.message,
      });
    },
  },
};
</script>
